// @flow
import {getFormFieldDefaults, getProductData} from 'service';
import {getPanelValidation} from 'shared/helpers';
import {cloneDeep} from 'lodash';
import {COMPONENT_TYPES} from 'shared';
import {ValidStructureInterface} from 'components/customer/Product/entity/ValidStructureInterface';

export const getDefaultProduct = async (
    room: number,
    productId: number,
    jobId: number,
    heightField: string
): any => {
    const roomId = typeof room === 'object' ? room.id : room;
    const productDefaults = cloneDeep(
        await getProductData(undefined, productId, roomId)
    );
    const productFormFieldDefaults = await getFormFieldDefaults(productId);

    if (
        productDefaults.hasOwnProperty('cabinet') &&
        productDefaults.cabinet.hasOwnProperty('attributes')
    ) {
        productDefaults.panelValidation = getPanelValidation(
            productDefaults.cabinet.attributes
        ); // remove before saving
    }
    productDefaults.jobId = jobId;
    productDefaults.roomId = roomId;
    productDefaults.material_type = productDefaults.ext_material.type;
    productDefaults.material_brand = productDefaults.ext_material.brand;
    productDefaults.finish = productDefaults.ext_material.finish;
    productDefaults.substrate = productDefaults.ext_material.substrate;
    productDefaults.colour = productDefaults.ext_material.id;
    productDefaults.edge_colour = productDefaults.ext_edge.id;
    productDefaults.unit_price = null;
    productDefaults.additional_door_width = null;
    productDefaults.hasAdvancedMaterialOptions = true;
    productDefaults.double_sided = !isNaN(productDefaults.ext_double_sided)
        ? parseInt(productDefaults.ext_double_sided)
        : 0;
    productDefaults.hor_grain = !isNaN(productDefaults.ext_hor_grain)
        ? parseInt(productDefaults.ext_hor_grain)
        : 0;
    productDefaults.custom_colour = productDefaults.custom_colour_ext;

    if (
        productDefaults.hasOwnProperty('doors') &&
        productDefaults.doors.length
    ) {
        productDefaults.door_hang_type =
            productDefaults.doors[0].door_hang_type;
        productDefaults.door_hang = productDefaults.doors[0].door_hang;
        productDefaults.border_width_top =
            productDefaults.doors[0].border_width_top;
        productDefaults.border_width_bottom =
            productDefaults.doors[0].border_width_bottom;
        productDefaults.border_width_left =
            productDefaults.doors[0].border_width_left;
        productDefaults.border_width_right =
            productDefaults.doors[0].border_width_right;
        productDefaults.hori_amount = productDefaults.doors[0].hori_amount;
        productDefaults.hori_height = productDefaults.doors[0].hori_height;
        productDefaults.vert_amount = productDefaults.doors[0].vert_amount;
        productDefaults.vert_width = productDefaults.doors[0].vert_width;
        productDefaults.no_vert_bars = productDefaults.doors[0].no_vert_bars;
        productDefaults.no_hori_bars = productDefaults.doors[0].no_hori_bars;
        productDefaults.bar_width = productDefaults.doors[0].bar_width;
        productDefaults.panel_edge_top =
            productDefaults.doors[0].panel_edge_top;
        productDefaults.panel_edge_bottom =
            productDefaults.doors[0].panel_edge_bottom;
        productDefaults.panel_edge_left =
            productDefaults.doors[0].panel_edge_left;
        productDefaults.panel_edge_right =
            productDefaults.doors[0].panel_edge_right;
        productDefaults.cabinet_edge_l1 =
            productDefaults.doors[0].panel_edge_top;
        productDefaults.cabinet_edge_l2 =
            productDefaults.doors[0].panel_edge_bottom;
        productDefaults.cabinet_edge_w1 =
            productDefaults.doors[0].panel_edge_left;
        productDefaults.cabinet_edge_w2 =
            productDefaults.doors[0].panel_edge_right;
        productDefaults.cabinet_panel_width = productDefaults.width;
        productDefaults.cabinet_width_door_1 = productDefaults.width;
        productDefaults.cabinet_width_door_2 = 0;
        if (productDefaults.hasOwnProperty('drill_only_hinge_details')) {
            productDefaults['drill_only_hinge'] =
                productDefaults.drill_only_hinge_details.drill_only_hinge;
            productDefaults['drill_only_hinge_name'] =
                productDefaults.drill_only_hinge_details.name;
        }

        productDefaults.glassSubPanelEnabledPositions = {
            sub_panel_1: false,
            sub_panel_enabled_1: false,
            sub_panel_2: false,
            sub_panel_enabled_2: false,
            sub_panel_3: false,
            sub_panel_enabled_3: false,
            sub_panel_4: false,
            sub_panel_enabled_4: false,
            sub_panel_5: false,
            sub_panel_enabled_5: true,
            sub_panel_6: false,
            sub_panel_enabled_6: false,
            sub_panel_7: false,
            sub_panel_enabled_7: false,
            sub_panel_8: false,
            sub_panel_enabled_8: false,
            sub_panel_9: false,
            sub_panel_enabled_9: false,
        };
    }

    productDefaults.drawer_face_height =
        productDefaults.drawer_face_height.filter(Boolean);
    productDefaults.inQfpSummary = 1;
    productDefaults.doorSizesOptions = {
        minimumHeight: null,
        maximumHeight: null,
        minimumWidth: null,
        maximumWidth: null,
    };

    productDefaults.drillings = [];

    if (
        productFormFieldDefaults.hasOwnProperty('cabinet_form_fields') &&
        productFormFieldDefaults.hasOwnProperty('cabinet_form_field_defaults')
    ) {
        productDefaults.glassSubPanels = [
            [false, false, false],
            [false, false, false],
            [false, false, false],
        ];
        Object.keys(productFormFieldDefaults.cabinet_form_fields).forEach(
            (field) => {
                const optionValue =
                    productFormFieldDefaults.cabinet_form_fields[field];
                const defaultValue =
                    productFormFieldDefaults.cabinet_form_field_defaults.hasOwnProperty(
                        field
                    )
                        ? productFormFieldDefaults.cabinet_form_field_defaults[
                              field
                          ]
                        : undefined;
                if ((optionValue == 1 || optionValue == 2) && defaultValue) {
                    if (productDefaults.hasOwnProperty(field)) {
                        productDefaults[field] = !isNaN(defaultValue)
                            ? parseInt(defaultValue)
                            : defaultValue;
                    }

                    if (
                        field.includes('panel_edge') ||
                        field.includes('cabinet_edge')
                    ) {
                        productDefaults[field] = !isNaN(defaultValue)
                            ? parseInt(defaultValue)
                            : defaultValue;
                    }

                    if (field.includes('supply_hardware')) {
                        productDefaults.cabinet_include_hardware = defaultValue;
                    }

                    if (field == 'drawer_amount' && defaultValue == 1) {
                        const height = productDefaults[heightField];

                        if (height) {
                            productDefaults.drawer_face_height = [height];
                        }
                    }
                }
                if (field.includes('sub_panel')) {
                    const subPanelIndex = parseInt(field.match(/\d+/g));
                    const row = Math.floor((subPanelIndex - 1) / 3);
                    const col = (subPanelIndex - 1) % 3;

                    productDefaults.glassSubPanels[row][col] =
                        productFormFieldDefaults.cabinet_form_field_defaults[
                            field
                        ] == 1;
                }
            }
        );
    }

    delete productDefaults.cabinet;
    delete productDefaults.carc_edge;
    delete productDefaults.carc_material;
    delete productDefaults.ext_material;
    delete productDefaults.ext_edge;

    return productDefaults;
};

// preview options are not exclusively boolean
export const getValidStructure = (structures): ValidStructureInterface => {
    let variationRequestStructure; // used to put variation request always last on the list
    let doorStructure; // used to put door form fields always first
    let panelDesignStructure; // used to put panel edging form fields always first
    let drawerPanelStructure;
    let hingeHoleStructure;
    let hasBorders = false;
    let hasBottomBorders = true;
    let hasEdging = true;
    let preview = COMPONENT_TYPES.QFP;
    let hasHorizontalGrain = true;
    let hasDoubleSided = true;
    let hasCustomColour = true;
    let hasCutout = false;
    let hasDoorType = true;
    let isPanel = false;
    let hasDoorHinge = false;
    let hasDoorHang = true;
    let heightField = 'height';
    let widthField = 'width';
    let heightFieldData = null;
    let widthFieldData = null;
    let isLattice = false;
    const fieldDisplayNameMap = {};

    const fieldFilter = [
        'cabinet_note',
        'cabinet_panel_length',
        'cabinet_panel_width',
    ];
    const sectionFilter = ['Materials'];
    const fildsetFilter = ['sizes'];

    const validStructure = cloneDeep(structures).filter((structure) => {
        structure.fieldsets = structure.fieldsets.filter((fieldset) => {
            if (!fieldset.hasOwnProperty('options')) fieldset.options = {};
            else fieldset.options.layout = '';

            if (fieldset.qfpTitle) {
                fieldset.title = fieldset.qfpTitle;
            }

            if (fieldset.name === 'additional') {
                fieldset.title = 'Hardware Selections';
            }

            if (fieldset.name === 'cabinet_edges') {
                fieldset.title = 'Panel Edging Design';
            }

            if (fieldset.name === 'advanced_door_glass') {
                fieldset.title = 'Glass Sub Panels';
            }

            if (fieldset.name === 'door_fields') {
                fieldset.title = 'Door Specs';
            }

            if (fieldset.name === 'panes') {
                isLattice = true;
            }

            fieldset.fields = fieldset.fields.filter((field) => {
                field.name = field.name.toLowerCase();
                if (field.displayName && field.displayName != null) {
                    fieldDisplayNameMap[
                        field.name
                    ] = `${fieldset.title} ${field.displayName}`;
                }

                if (
                    field.name === 'door_hang' &&
                    field.hasOwnProperty('options') &&
                    field.options.hasOwnProperty('visible')
                ) {
                    hasDoorHang = field.options.visible;
                }

                if (
                    field.name === 'hinge_style' &&
                    field.hasOwnProperty('options') &&
                    field.options.hasOwnProperty('visible')
                ) {
                    hasDoorHinge = field.options.visible;
                } else {
                    hasDoorHinge = false;
                }

                if (field.name.indexOf('edge_l1') > -1) {
                    isPanel = true;
                }

                if (
                    structure.name === 'Materials' &&
                    field.hasOwnProperty('options') &&
                    field.name == 'cabinet_ext_colour'
                ) {
                    if (field.options.hasOwnProperty('horGrainVisible')) {
                        hasHorizontalGrain = field.options.horGrainVisible == 1;
                    }

                    if (field.options.hasOwnProperty('doubleSidedVisible')) {
                        hasDoubleSided = field.options.doubleSidedVisible == 1;
                    }

                    if (field.options.hasOwnProperty('customColourVisible')) {
                        hasCustomColour =
                            field.options.customColourVisible == 1;
                    }
                }

                // need to exclude mid rails as they are having Height as display names
                const midRailsFields = ['vert_width', 'hori_height'];
                if (
                    (field.displayName === 'Height' ||
                        field.displayName === 'Length') &&
                    !midRailsFields.includes(field.name)
                ) {
                    heightField = field.name;
                    heightFieldData = field;
                }

                if (field.displayName === 'Width') {
                    widthField = field.name;
                    widthFieldData = field;
                }

                if (
                    field.name === 'door_hang_type' &&
                    field.hasOwnProperty('options') &&
                    field.options.hasOwnProperty('visible')
                ) {
                    if (
                        field.hasOwnProperty('value') &&
                        field.options.visible == 0
                    ) {
                        hasDoorType = field.value == 1;
                    } else {
                        hasDoorType = field.options.visible;
                    }
                }

                if (
                    field.name == 'border_width_bottom' &&
                    field.options.hasOwnProperty('visible') &&
                    (field.options.visible == 0 ||
                        field.options.visible == false)
                ) {
                    hasBottomBorders = false;
                }

                if (
                    field.name == 'sub_panel' &&
                    field.hasOwnProperty('options') &&
                    field.options.hasOwnProperty('subPanelSettings') &&
                    Array.isArray(field.options.subPanelSettings)
                ) {
                    const allSettings = field.options.subPanelSettings.map(
                        (setting) => setting && setting.enabled == 0
                    );

                    field.options.visible = !(
                        allSettings.length == 9 && allSettings.every(Boolean)
                    );
                }

                return !fieldFilter.includes(field.name);
            });

            if (
                structure.name === 'Doors' &&
                fieldset.name == 'border_widths'
            ) {
                if (
                    fieldset.options &&
                    fieldset.options.visible &&
                    fieldset.options.visible === 'cabinet_door.advanced'
                ) {
                    hasCutout = false;
                } else {
                    hasCutout = true;
                }
            }

            if (structure.name === 'Doors' && fieldset.name == 'mid_rails') {
                const midrailFields = fieldset.fields
                    .map((field) => field.options.visible)
                    .every((value) => value == 0 || value == false);

                if (!midrailFields) {
                    preview = COMPONENT_TYPES.DOOR;
                }
            }

            if (structure.name === 'Doors' && fieldset.name == 'panes') {
                preview = COMPONENT_TYPES.DOOR;
            }

            if (fieldset.name === 'drawer_fields') {
                fieldset.title = 'Drawer Specs';
                const visibleDrawerFields = fieldset.fields
                    .map((field) => field.options.visible)
                    .every((value) => value == 0 || value == false);

                if (!visibleDrawerFields) {
                    preview = COMPONENT_TYPES.DRAWER;
                }
            }

            if (
                preview !== COMPONENT_TYPES.DOOR &&
                fieldset.name === 'advanced_door_glass'
            ) {
                fieldset.options.visible = 0;
            }

            if (fieldset.name == 'comments') {
                variationRequestStructure = fieldset;

                return false;
            }

            if (
                fieldset.name == 'cabinet_edges' ||
                fieldset.name == 'door_edges'
            ) {
                panelDesignStructure = fieldset;

                if (
                    fieldset.options &&
                    fieldset.options.hasOwnProperty('visible')
                ) {
                    hasEdging = fieldset.options.visible;
                }

                const fieldsVisible = fieldset.fields.map(
                    (field) => field.options && field.options.visible
                );

                if (
                    fieldsVisible.every((value) => value == 0) ||
                    fieldsVisible.every((value) => value == false)
                ) {
                    hasEdging = false;
                }

                return false;
            }

            if (fieldset.name == 'drawer_panel_edges') {
                drawerPanelStructure = fieldset;

                return false;
            }

            if (fieldset.name == 'door_drillings') {
                hingeHoleStructure = fieldset;

                return false;
            }

            if (fieldset.name == 'border_widths') {
                if (
                    fieldset.options &&
                    fieldset.options.enabled &&
                    (fieldset.options.enabled == 1 ||
                        fieldset.options.enabled == true)
                ) {
                    hasBorders = true;
                } else {
                    hasBorders = 'cabinet_door.advanced';
                }
            }

            return !fildsetFilter.includes(fieldset.name);
        });

        if (structure.name == 'Doors') {
            doorStructure = structure;

            return false;
        }

        return !sectionFilter.includes(structure.name);
    });

    if (variationRequestStructure) {
        validStructure.push({
            name: 'Comments',
            preview: 'standard',
            fieldsets: [variationRequestStructure],
        });
    }

    if (doorStructure) {
        validStructure.unshift(doorStructure);
    }

    if (panelDesignStructure) {
        const fieldsets = [panelDesignStructure];

        if (drawerPanelStructure) {
            fieldsets.push(drawerPanelStructure);
        }

        validStructure.unshift({
            name: 'Edging',
            preview: 'standard',
            fieldsets: fieldsets,
        });
    }

    validStructure.unshift(getMaterialFields());

    if (hingeHoleStructure) {
        validStructure[2].fieldsets.splice(1, 0, hingeHoleStructure);
    }

    return {
        validStructure,
        hasBorders,
        hasBottomBorders,
        hasEdging,
        preview,
        hasCutout,
        hasDoorType,
        isPanel,
        hasDoorHinge,
        hasDoorHang,
        heightField,
        widthField,
        hasHorizontalGrain,
        hasDoubleSided,
        hasCustomColour,
        heightFieldData,
        widthFieldData,
        fieldDisplayNameMap,
        isLattice,
    };
};

const getMaterialFields = () => {
    return {
        name: 'Materials',
        preview: 'standard',
        fieldsets: [
            {
                name: 'material_options',
                title: 'Material Options',
                fields: [
                    {
                        name: 'hor_grain',
                        displayName: 'Horizontal Grain',
                        type: 'boolean',
                        value: null,
                        options: {
                            visible:
                                'AND(cabinet_ext_colour.horGrain, hasHorizontalGrain)',
                        },
                    },
                    {
                        name: 'double_sided',
                        displayName: 'Double Sided',
                        type: 'boolean',
                        value: null,
                        options: {
                            visible:
                                'AND(cabinet_ext_colour.doubleSided, hasDoubleSided)',
                        },
                    },
                    {
                        name: 'custom_colour',
                        displayName: 'Custom Colour',
                        type: 'text',
                        value: null,
                        options: {
                            visible:
                                'AND(cabinet_ext_colour.customColour, hasCustomColour)',
                        },
                    },
                ],
                options: {
                    visible: `OR(AND(cabinet_ext_colour.horGrain, hasHorizontalGrain), AND(cabinet_ext_colour.doubleSided, hasDoubleSided), AND(cabinet_ext_colour.customColour, hasCustomColour))`,
                },
            },
        ],
    };
};
